import { Routes } from '@angular/router';
import { authGuard } from './guards/auth.guard';
import { notAuthGuard } from './guards/not-auth.guard';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { PermissionedAction } from './types/permission';
import { hasPermissionGuard } from './guards/has-permission.guard';
import { UserRole } from './types';

export const APP_ROUTES: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  { path: 'auth', redirectTo: 'login' },
  {
    path: 'login',
    loadComponent: () =>
      import('./pages/auth/auth.component').then((m) => m.AuthComponent),
    canActivate: [notAuthGuard],
    resolve: {
      asdasdsad: () => {
        console.log('RESOLVER WORKS:');
        return [];
      },
    },
  },
  {
    path: 'home',
    loadComponent: () =>
      import('./pages/home-page/home.component').then((m) => m.HomeComponent),
    canActivate: [authGuard],
    canActivateChild: [hasPermissionGuard],
    children: [
      {
        path: '',
        loadComponent: () => import('./pages/dashboard-page/dashboard.component').then((m) => m.DashboardComponent),
      },
      {
        path: 'folder',
        loadComponent: () =>
          import('./pages/folder/folder.component').then(
            (m) => m.FolderPageComponent
          ),
        data: { permission: PermissionedAction.ReadFileMetadatas },
      },
      {
        path: 'inbox',
        loadComponent: () =>
          import('./pages/inbox/inbox.component').then(
            (m) => m.InboxPageComponent
          ),
        data: { permission: PermissionedAction.ReadInboxDocumentLogs },
      },
      {
        path: 'outbox',
        loadComponent: () =>
          import('./pages/outbox/outbox.component').then(
            (m) => m.OutboxPageComponent
          ),
        data: { permission: PermissionedAction.ReadOutboxUserDocumentLogs },
      },
      {
        path: 'workqueue',
        loadComponent: () =>
          import('./pages/workqueue/workqueue.component').then(
            (m) => m.WorkqueueComponent
          ),
        data: { permission: PermissionedAction.ReadWorkqueueDocumentLogs },
      },
      {
        path: 'control',
        loadComponent: () =>
          import('./pages/control/control.component').then(
            (m) => m.ControlComponent
          ),
      },
      {
        path: 'search',
        loadComponent: () =>
          import('./pages/search/search.component').then(
            (m) => m.SearchPageComponent
          ),
        data: { permission: PermissionedAction.ReadSearch },
      },
      {
        path: 'researchers',
        loadComponent: () =>
          import('./pages/researchers/researchers-page.component').then(
            (m) => m.ResearchersPageComponent
          ),
        data: { permission: PermissionedAction.ReadResearchers },
      },
      {
        path: 'research',
        loadComponent: () =>
          import('./pages/research/research-page.component').then(
            (m) => m.ResearchPageComponent
          ),
        data: { permission: PermissionedAction.ReadResearch },
      },
      {
        path: 'companies/archives',
        loadComponent: () =>
          import(
            './pages/companies/company-list-page/company-list-page.component'
          ).then((m) => m.CompanyListPageComponent),
        data: { permission: PermissionedAction.ReadArchvies, type: 'archives' },
      },
      {
        path: 'companies/organizations',
        loadComponent: () =>
          import(
            './pages/companies/company-list-page/company-list-page.component'
          ).then((m) => m.CompanyListPageComponent),
        data: {
          permission: PermissionedAction.ReadOrganizations,
          type: 'organizations',
        },
      },
      {
        path: 'company/:id',
        loadComponent: () =>
          import(
            './pages/companies/company-info-page/company-info-page.component'
          ).then((m) => m.CompanyInfoPageComponent),
      },
      {
        path: 'funds/:id',
        loadComponent: () =>
          import('./pages/fund-info-page/fund-info-page.component').then(
            (m) => m.FundInfoPageComponent
          ),
      },
      {
        path: 'lists/:id',
        loadComponent: () =>
          import('./pages/list-info-page/list-info-page.component').then(
            (m) => m.ListInfoPageComponent
          ),
      },
      {
        path: 'cases/:id',
        loadComponent: () =>
          import('./pages/case-info-page/case-info-page.component').then(
            (m) => m.CaseInfoPageComponent
          ),
      },
      {
        path: 'documents/:id',
        loadComponent: () =>
          import(
            './pages/document-info-page/document-info-page.component'
          ).then((m) => m.DocumentInfoPageComponent),
      },
      {
        path: 'workqueue/register/:id',
        loadComponent: () =>
          import('./pages/register/register-page.component').then(
            (m) => m.RegisterPageComponent
          ),
      },
      {
        path: 'parameters',
        loadComponent: () =>
          import('./pages/parameters/parameters.component').then(
            (m) => m.ParametersComponent
          ),
        data: { permission: PermissionedAction.ReadParameters, ignoreUser: true },
        children: [
          { path: '', pathMatch: 'full', redirectTo: '1' },
          {
            path: ':type',
            loadComponent: () =>
              import(
                './pages/parameters/parameter-list-page/parameter-list-page.component'
              ).then((m) => m.ParameterListPageComponent),
          },
        ],
      },
      {
        path: 'logs',
        loadComponent: () =>
          import('./pages/logs-page/logs.component').then(
            (m) => m.LogsComponent
          ),
        data: { permission: PermissionedAction.ReadLogs },
        children: [
          { path: '', pathMatch: 'full', redirectTo: '1' },
          {
            path: ':objectType',
            loadComponent: () =>
              import(
                './pages/logs-page/log-list-page/log-list-page.component'
              ).then((m) => m.LogListPageComponent),
          },
        ],
      },
    ],
  },
  { path: '**', component: NotFoundPageComponent },
];
