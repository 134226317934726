import { NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, SimpleChange, SimpleChanges, inject } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { DocumentGroup, FileMetadata } from "src/app/types";

@Component({
    selector: 'app-file-metadata-viewer',
    templateUrl: './file-metadata-viewer.component.html',
    styleUrls: ['./file-metadata-viewer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        MatIconModule,
        MatButtonModule
    ]
})
export class FileMetadataViewerComponent {
    DocumentGroup = DocumentGroup;
    private _sanitizer = inject(DomSanitizer);

    @Input() fileMetadata: FileMetadata | null = null;
    @Input() part: number | null = null;
    @Output() partChange = new EventEmitter<number>();
    currentPageStart = 1;
    currentPageEnd = 100;
    pageSize = 100;

    @Input()
    get signedUrl() { return this._signedUrl }
    set signedUrl(url: string | null) {
        this._signedUrl = url;
        this.safeUrl = !!url ? this._sanitizer.bypassSecurityTrustResourceUrl(url) : null
    }
    private _signedUrl: string | null = null

    ngOnInit() {
        console.log("FILE METADATA", this.fileMetadata);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['fileMetadata']) {
            const previousId = changes['fileMetadata'].previousValue?.id;
            const currentId = changes['fileMetadata'].currentValue?.id;

            if (previousId !== currentId) {
                this.resetPageRange();
            }
        }
    }

    safeUrl: SafeResourceUrl | null = null;

    get documentGroup() {
        return this.fileMetadata?.documentGroup;
    }

    nextPageRange() {
        if (this.fileMetadata && this.currentPageEnd < this.fileMetadata?.pageCount) {
            this.currentPageStart += this.pageSize;
            this.currentPageEnd = Math.min(this.currentPageEnd + this.pageSize, this.fileMetadata.pageCount);
        }
    }

    previousPageRange() {
        if (this.currentPageStart > 1) {
            this.currentPageStart -= this.pageSize;
            this.currentPageEnd = this.currentPageStart + this.pageSize - 1;
        }
    }

    nextPart() {
        // console.log('NEXT PART', this.part);
        // console.log("FILE METADATA []", this.fileMetadata);
        if (this.part) {
            this.partChange.emit(this.part + 1)
            this.nextPageRange();
        }
    }

    previousPart() {
        if (this.part) {
            this.partChange.emit(this.part - 1)
            this.previousPageRange();
        }
    }


    private resetPageRange() {
        this.currentPageStart = 1;
        this.currentPageEnd = this.pageSize;
    }
}