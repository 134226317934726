<div *ngIf="fileMetadata" class="file-info">
    <div class="file-name">
        <p>{{fileMetadata.originalName}}</p>
        <div *ngIf="!!fileMetadata.partCount && !!part && documentGroup == DocumentGroup.Paper"
            style="display: flex; align-items: center;">
            <button mat-icon-button [disabled]="part <= 1" (click)="previousPart()">
                <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
            <p>{{currentPageStart}}-{{currentPageEnd}}</p>
            <button mat-icon-button [disabled]="part >= fileMetadata.partCount" (click)="nextPart()">
                <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
        </div>
    </div>
    <ng-container *ngIf="safeUrl">
        <section class="file-viewer-container">
            <iframe *ngIf="documentGroup == DocumentGroup.Paper" [src]="safeUrl"></iframe>
            <video *ngIf="documentGroup == DocumentGroup.Movie" controls>
                <source [src]="safeUrl" [type]="fileMetadata.contentType">
            </video>
            <audio *ngIf="documentGroup == DocumentGroup.Sound" controls>
                <source [src]="safeUrl" [type]="fileMetadata.contentType">
            </audio>
            <img *ngIf="documentGroup == DocumentGroup.Photo" [src]="safeUrl" />
            <p *ngIf="documentGroup == DocumentGroup.Unknown">Sənədi göstərmək mümkün olmadı...</p>
        </section>
    </ng-container>
</div>