import { ErrorHandler, Injectable, inject } from '@angular/core';
import { User, UserCredentials } from '../types';
import { AuthService } from '../services/auth.service';
import { StorageService } from '../services/storage.service';
import { Router } from '@angular/router';
import {
  Observable,
  distinctUntilChanged,
  filter,
  forkJoin,
  mergeMap,
  switchMap,
  tap,
} from 'rxjs';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import { UiService } from '../services/ui.service';
import { PermissionedActionValues } from '../types/permission';

export interface AuthState {
  token: string | null;
  user: User | null;
  permissions: Partial<Record<PermissionedActionValues, boolean>> | null;
}

@Injectable({
  providedIn: 'root',
})
export class AuthStore extends ComponentStore<AuthState> {
  constructor(
    private storageService: StorageService,
    private authService: AuthService,
    private router: Router,
    private uiService: UiService,
    private errorHandler: ErrorHandler
  ) {
    super({
      user: null,
      token: null,
      permissions: null,
    });
  }

  public readonly token$ = this.select((state) => state.token);
  public readonly user$ = this.select((state) => state.user);
  public readonly permissions$ = this.select((state) => state.permissions);

  public setToken(token: string | null) {
    this.patchState({ token });
  }

  private _setUser(user: User | null) {
    this.patchState({ user });
  }

  private _setPermissions(
    permissions: Partial<Record<PermissionedActionValues, boolean>> | null
  ) {
    this.patchState({ permissions });
  }

  public login(credentials: UserCredentials) {
    this._login(credentials);
  }

  public fetchUser() {
    this._fetchUser();
  }

  public logout() {
    this._reset();
    this.router.navigateByUrl('/auth');
  }

  private _reset = () => {
    this.storageService.removeToken();
    this.patchState({
      token: null,
      user: null,
      permissions: null,
    });
  };

  private _login = this.effect((credentials$: Observable<UserCredentials>) =>
    credentials$.pipe(
      tap((_) => this.uiService.showLoader(true)),
      mergeMap((credentials) =>
        this.authService.login(credentials).pipe(
          tapResponse(
            (result) => {
              this.uiService.showLoader(false);
              this.storageService.saveToken(result);
              this.setToken(result);
              this.router.navigateByUrl('/home');
            },
            (error: Error) => {
              this.uiService.showLoader(false);
              this.errorHandler.handleError(error);
            }
          )
        )
      )
    )
  );

  private _fetchUser = this.effect(($: Observable<void>) =>
    $.pipe(
      tap((_) => this.uiService.showLoader(true)),
      switchMap((_) =>
        this._fetchUserInfo().pipe(
          tapResponse(
            ([user, permissions]) => {
              this.uiService.showLoader(false);
              this._setUser(user);
              this._setPermissions(permissions);
            },
            (error) => {
              this.uiService.showLoader(false);
              this.errorHandler.handleError(error);
              this.logout();
            }
          )
        )
      )
    )
  );

  private _fetchUserInfo() {
    return forkJoin([
      this.authService.fetchUserInfo(),
      this.authService.fetchUserPermissions(),
    ]);
  }
}

export function useUserPermissions() {
  const authStore = inject(AuthStore);
  return authStore.permissions$.pipe(
    filter((p) => !!p),
    distinctUntilChanged()
  );
}
